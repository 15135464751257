import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-typed-observable-plugin';
import './action-slot-more-menu.scss';

export class ActionSlotMoreMenu {
    @bindable actions: Array<TActionSlotMoreMenuAction>;
}

export type TActionSlotMoreMenuAction = {
    label: TranslationKey;
    handler: () => void;
    icon: string;
};
