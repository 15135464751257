import type { StandardApiResponseWithData } from '@nexdynamic/squeegee-common';
import { sortByCreatedDateDesc, type Notification, type NotificationStatus } from '@nexdynamic/squeegee-common';
import { Data } from '../../Data/Data';
import { Api } from '../../Server/Api';

type FetchTransactionNotificationStatusResult =
    | {
          success: true;
          status: {
              sms?: NotificationStatus | null;
              email?: NotificationStatus | null;
          };
      }
    | {
          success: false;
          error: string;
      };

let _cachedOldestAvailableNotificationDate: string | null | undefined = null;

export const getOldestAvailableNotificationDate = (): string | null => {
    if (_cachedOldestAvailableNotificationDate !== undefined) {
        return _cachedOldestAvailableNotificationDate;
    }
    const notifications = Data.all<Notification>('notifications');
    if (notifications.length === 0) {
        return null;
    }

    let oldestDate = notifications[0].createdDate;
    for (const notification of notifications) {
        if (notification.createdDate < oldestDate) {
            oldestDate = notification.createdDate;
        }
    }
    _cachedOldestAvailableNotificationDate = oldestDate;
    // After 5 minutes, we'll refresh the cache
    setTimeout(() => {
        _cachedOldestAvailableNotificationDate = undefined;
    }, 5 * 60 * 1000);

    return oldestDate;
};

export const fetchTransactionNotificationStatus = async (
    invoiceId: string,
    invoiceCreatedDate: string,
    forceCheck = false
): Promise<FetchTransactionNotificationStatusResult> => {
    try {
        const oldestNoficationDate = getOldestAvailableNotificationDate();

        // if the invoice created date is older than the oldest notification date, we can return an unknown status;
        if (!forceCheck && oldestNoficationDate && invoiceCreatedDate < oldestNoficationDate) {
            return {
                success: false,
                error: 'Notification status is unknown',
            };
        }

        const notification = Data.all<Notification>('notifications', { relatedItemId: invoiceId }).slice().sort(sortByCreatedDateDesc);
        const sms = notification.find(n => n.type === 'SMS')?.status;
        const email = notification.find(n => n.type === 'Email')?.status;
        if (sms || email) {
            return {
                success: true,
                status: {
                    sms,
                    email,
                },
            };
        }

        // TODO: Replace this with a bulk fetch from server

        if (forceCheck) {
            const result = await Api.get<StandardApiResponseWithData<FetchTransactionNotificationStatusResult>>(
                null,
                `/api/notification_status_for_transaction/${invoiceId}`
            );
            if (result?.data.success === true && result.data.data.success === true) {
                return result.data.data;
            }
        }
        return {
            success: false,
            error: 'Unable to fetch notification status for transaction',
        };
    } catch (error) {
        console.error(`Error in fetchTransactionNotificationStatus for transaction ${invoiceId}`, error);
        return {
            success: false,
            error: 'Unable to fetch notification status for transaction',
        };
    }
};
