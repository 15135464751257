import type { ITab } from '@nexdynamic/squeegee-common';
import type { Subscription as EventSub } from 'aurelia-event-aggregator';
import { bindable, computedFrom } from 'aurelia-framework';
import { AlertCountUpdateEvent } from '../Alerts/AlertCountUpdateEvent';
import { ApplicationState } from '../ApplicationState';
import type { IAvatar } from '../Components/IAvatar';
import { Data } from '../Data/Data';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import { MenuBarActionsEvent } from '../Events/MenuBarActionsEvent';
import type { IMenuBarAction } from './IMenuBarAction';

export class MenuBar {
    @bindable protected title: string;
    @bindable protected tabs: Array<ITab>;
    public applicationState = ApplicationState;

    protected avatar: IAvatar;
    protected actions: Array<IMenuBarAction> | undefined;
    private menuBarActionEventSubscription: EventSub;
    private alertCountUpdateSubscription: EventSub;
    private dataRefreshSubscription: EventSub;
    protected alertCount = ApplicationState.isInAnyRole(['Admin', 'Owner']) ? Data.count('alerts') : 0;

    @computedFrom('alertCount')
    protected get alertCountFormatted() {
        return this.alertCount > 99 ? '99+' : this.alertCount.toString();
    }
    protected viewOptions = ApplicationState.viewOptions;
    attached() {
        this.menuBarActionEventSubscription = MenuBarActionsEvent.subscribe((event: MenuBarActionsEvent) => {
            if (event.actions === undefined) {
                this.actions = undefined;
                this.hideMoreActions();
            } else this.actions = event.actions.filter(x => !x.roles?.length || ApplicationState.isInAnyRole(x.roles));
        });
        this.alertCountUpdateSubscription = AlertCountUpdateEvent.subscribe<AlertCountUpdateEvent>(x => {
            this.alertCount = x.count;
        });
        this.dataRefreshSubscription = DataRefreshedEvent.subscribe<DataRefreshedEvent>(e => {
            if (!e.hasAnyType('alerts')) return;
            this.alertCount = AlertCountUpdateEvent.getCount();
        });
    }
    detached() {
        this.menuBarActionEventSubscription && this.menuBarActionEventSubscription.dispose();
        this.alertCountUpdateSubscription && this.alertCountUpdateSubscription.dispose();
        this.dataRefreshSubscription && this.dataRefreshSubscription.dispose();
    }

    public goto(routeTab: ITab) {
        if (!ApplicationState.hasMinimumSubscription(routeTab.minimumSubscription)) return;
        ApplicationState.navigateToRouteFragment(routeTab.routeFragment, { replace: true });
    }

    public hideMoreActions() {
        const menuEl = document.querySelector('#menuBar > div.mdl-layout__header-row > div:nth-child(4) > div');
        if (menuEl) menuEl.classList.remove('is-visible');
    }

    protected runActionHandler(action: IMenuBarAction) {
        action.handler();
        this.hideMoreActions();
    }
}
