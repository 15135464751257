// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.sample-message-dialog .dialog-content {
   overflow: hidden;
   padding-bottom: calc(var(--notch-inset-bottom) + 52px);

}

.sample-message-dialog__msg {
    width: 80%;
    margin-left: auto;
    max-width: 364px;
    padding: 8px 16px;
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    position: relative;
    white-space: pre-line;
}


.sample-message-is-email {
    white-space: normal;
}


.sample-message-dialog-container {
    height: 100%;
    overflow-y: auto;
    padding: 24px 12px;
    background: var(--themed-background-light);
}

.sample-message-is-email { 
    width: 100%;
    max-width: 100%;
    padding: 16px;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/Notifications/SampleMessageDialog.css"],"names":[],"mappings":";;AAEA;GACG,gBAAgB;GAChB,sDAAsD;;AAEzD;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,wCAAwC;IACxC,kBAAkB;IAClB,qBAAqB;AACzB;;;AAGA;IACI,mBAAmB;AACvB;;;AAGA;IACI,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,0CAA0C;AAC9C;;AAEA;IACI,WAAW;IACX,eAAe;IACf,aAAa;IACb,gBAAgB;IAChB,gBAAgB;IAChB,SAAS;AACb","sourcesContent":["\n\n.sample-message-dialog .dialog-content {\n   overflow: hidden;\n   padding-bottom: calc(var(--notch-inset-bottom) + 52px);\n\n}\n\n.sample-message-dialog__msg {\n    width: 80%;\n    margin-left: auto;\n    max-width: 364px;\n    padding: 8px 16px;\n    border-radius: 18px;\n    background-color: #ffffff;\n    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);\n    position: relative;\n    white-space: pre-line;\n}\n\n\n.sample-message-is-email {\n    white-space: normal;\n}\n\n\n.sample-message-dialog-container {\n    height: 100%;\n    overflow-y: auto;\n    padding: 24px 12px;\n    background: var(--themed-background-light);\n}\n\n.sample-message-is-email { \n    width: 100%;\n    max-width: 100%;\n    padding: 16px;\n    border-radius: 0;\n    box-shadow: none;\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
