import type { OneSignalPlugin } from 'onesignal-cordova-plugin';
import type { OneSignal as OneSignalWebSdk } from 'onesignal-website-sdk-types';
import { GlobalFlags } from './GlobalFlags';

export class OneSignal {
    public static get instance() {
        return GlobalFlags.isHttp ? OneSignal.web : OneSignal.app;
    }
    public static get app(): OneSignalPlugin | undefined {
        return (window as any).plugins?.OneSignal;
    }
    public static get web(): OneSignalWebSdk | undefined {
        return document.location.hostname === 'app.squeeg.ee' ? (window as any).OneSignal : undefined;
    }
}
