import type { Notification } from '@nexdynamic/squeegee-common';
import type { EmailTemplateProps, TemplateName } from '@nexdynamic/squeegee-templates';
import { ApplicationState } from '../ApplicationState';
import { Utilities } from '../Utilities';
import { MessageUtils } from './MessageUtils';

export const enum SnippetType {
    EMAIL = 'email',
    REPLY = 'reply',
}

export type EmailVariant = 'email.simple';

export class Snippet {
    public content: string;
    public text: string;

    constructor(public type: SnippetType, notification: Notification) {
        const content = MessageUtils.getContentFromNotification(notification);
        this.content = content;
        this.text = Utilities.extractTextFromHtml(content);
    }
}

export class TemplateSnippet<TModel> extends Snippet {
    constructor(public snippetType: SnippetType, notification: Notification, public templateName: TemplateName, public model: TModel) {
        super(snippetType, notification);
    }
}

export class EmailSnippet extends TemplateSnippet<EmailTemplateProps> {
    constructor(notification: Notification) {
        const { businessLogo, name, bccEmailNotifications, businessName } = ApplicationState.account;

        super(SnippetType.EMAIL, notification, 'email.simple', {
            content: MessageUtils.getContentFromNotification(notification),
            logo: businessLogo,
            name,
            businessName,
            businessEmail: bccEmailNotifications,
        });
    }
}
