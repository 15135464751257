import type { Notification, StandardApiResponse } from '@nexdynamic/squeegee-common';
import { sortByCreatedDateDesc } from '@nexdynamic/squeegee-common';
import { Data } from '../Data/Data';
import { Logger } from '../Logger';
import { Api } from '../Server/Api';

export class NotificationService {
    /**
     * Returns all notifications related the given customer sorted newest fist
     * @static
     * @param {string} customerId
     * @return {*}  {Array<Notification>}
     * @memberof NotificationService
     */
    public static getAllForCustomer(customerId: string): Array<Notification> {
        const sent = Data.all<Notification>('notifications', { addressee: customerId }).slice(0);
        const replies = NotificationService.getAllCustomerReplies(customerId);

        const notifications = [...sent, ...replies];

        notifications.sort(sortByCreatedDateDesc);

        return notifications;
    }
    /**
     * Returns all notifications replies for the given customer
     * @static
     * @param {string} customerId
     * @return {*}  {Array<Notification>}
     * @memberof NotificationService
     */
    public static getAllCustomerReplies(customerId: string): Array<Notification> {
        const replies = Data.all<Notification>('notifications', { customerId: customerId, type: 'SMSReply' }).slice(0);
        const emailReplies = Data.all<Notification>('notifications', { customerId: customerId, type: 'EmailReply' }).slice(0);
        const chats = Data.all<Notification>('notifications', { customerId: customerId, type: 'ChatReply' }).slice(0);
        const feedback = Data.all<Notification>('notifications', { customerId: customerId, type: 'Feedback' }).slice(0);
        return replies.concat(feedback, chats, emailReplies);
    }
    /**
     * Returns an array of all stored notifications
     * @static
     * @memberof NotificationService
     */
    public static getAll() {
        return Data.all<Notification>('notifications');
    }

    /**
     * Marks the notification as read
     *
     * @static
     */
    public static async markAsRead(notificationIds: Array<string>) {
        try {
            // Find all the notifications that are not stored locally
            const notificationIdsNotStoredLocally = notificationIds.filter(id => !Data.get<Notification>(id));
            // Find all the notifications that are stored locally
            const notificationsStoredLocally = Data.all<Notification>('notifications').filter(n => notificationIds.includes(n._id));

            // Mark all the notifications stored locally as read
            const toUpdate = [];
            for (const notification of notificationsStoredLocally) {
                notification.unread = false;
                toUpdate.push(notification);
            }
            // Mark all the notifications not stored locally as read via the API
            if (notificationIdsNotStoredLocally.length) {
                await Api.post<StandardApiResponse>(null, '/api/notifications/mark_as_read', {
                    notificationIds: notificationIdsNotStoredLocally,
                });
            }

            // Update the local notifications
            if (toUpdate.length) Data.put(toUpdate);
        } catch (error) {
            Logger.error('Unable to mark notifications as read', error);
            throw new Error('Unable to mark notifications as read');
        }
    }

    /**
     * Cancels a scheduled notification
     *
     * @static
     */
    public static cancelScheduledNotifications = async (selectedNotifications: Array<Notification>): Promise<boolean> => {
        try {
            const result = await Api.put<StandardApiResponse>(null, '/api/notifications/schedule/cancel', {
                notifications: selectedNotifications.map(item => item._id),
            });

            return result?.data?.success || false;
        } catch (error) {
            Logger.error(error);
            return false;
        }
    };
}
