import type { TranslationKey, UserRole } from '@nexdynamic/squeegee-common';
import { uuid } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode, computedFrom } from 'aurelia-framework';
import type { Router } from 'aurelia-router';
import { ApplicationState } from '../ApplicationState';
import { Logger } from '../Logger';
import type { IMenuBarAction } from './IMenuBarAction';

export class ContextMenuBar {
    public static previousColors: Array<string> = [];

    @bindable router: Router;
    @bindable public statusbarColor: string;
    @bindable public previousColor: string;

    @bindable({ defaultBindingMode: bindingMode.toView }) title: TranslationKey;
    @bindable({ defaultBindingMode: bindingMode.toView }) titleParams: { [key: string]: string };

    protected localisedTitle = '';
    @bindable({ defaultBindingMode: bindingMode.toView }) actions: Array<IMenuBarAction>;
    @bindable({ defaultBindingMode: bindingMode.toView }) close: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView }) save: () => void;
    @bindable({ defaultBindingMode: bindingMode.toView }) moreActions: Array<IMenuBarAction>;
    @bindable({ defaultBindingMode: bindingMode.toView }) closeIcon: string;
    @bindable({ defaultBindingMode: bindingMode.toView }) saveIcon: string;
    @bindable({ defaultBindingMode: bindingMode.toView }) saveText: TranslationKey = 'general.save';
    @bindable({ defaultBindingMode: bindingMode.twoWay }) statusbarTextLight = true;

    protected viewOptions = ApplicationState.viewOptions;
    protected randomId = uuid();

    protected handlerVisible(roles?: Array<UserRole>) {
        return !roles?.length || ApplicationState.isInAnyRole(roles);
    }

    @computedFrom('actions')
    protected get filteredActions() {
        return (this.actions || []).filter(a => this.handlerVisible(a.roles));
    }

    @computedFrom('moreActions')
    protected get filteredMoreActions() {
        return (this.moreActions || []).filter(a => this.handlerVisible(a.roles));
    }

    public attached() {
        if (this.title !== undefined) {
            this.localisedTitle = ApplicationState.localise(this.title, this.titleParams);
        }
    }

    public detached() {
        try {
            if ((<any>window).StatusBar) {
                const currentColorIndex = ContextMenuBar.previousColors.indexOf(this.statusbarColor);
                if (currentColorIndex > -1) {
                    ContextMenuBar.previousColors.splice(currentColorIndex);
                    const colorToChangeTo = ContextMenuBar.previousColors[currentColorIndex - 1];
                    if (colorToChangeTo && colorToChangeTo.toLowerCase() === '#ffffff') (<any>window).StatusBar.styleDefault();
                    else (<any>window).StatusBar.styleLightContent();
                }
            }
        } catch (error) {
            return void Logger.error(`Error during detach in context menu bar`, { contextMenuBar: this, error });
        }
    }

    public delegateClose = () => {
        this.close();
    };

    public delegateSave = () => {
        this.save();
    };
}
