import { Api } from '../../Server/Api';

let unreadCountCached: number | undefined = 0;

export const fetchNotificationsUnreadCount = async () => {
    try {
        if (unreadCountCached !== undefined) return unreadCountCached;

        const result = await Api.get<{ count: number }>(null, '/api/notifications/unread_count', undefined, undefined, true);

        if (!result?.data) return unreadCountCached;

        if (result?.data?.count && isNaN(result?.data.count)) return unreadCountCached;

        unreadCountCached = result.data.count;

        return unreadCountCached;
    } catch (error) {
        return unreadCountCached;
    } finally {
        // Clear the cache after 15 seconds
        setTimeout(() => {
            unreadCountCached = undefined;
        }, 1000 * 15);
    }
};
