import { Api } from '../../Server/Api';

let customerUnreadCountCachedRecord: { [customerId: string]: number } = {};

export const fetchNotificationsUnreadCountByCustomerId = async (customerId: string) => {
    try {
        if (customerUnreadCountCachedRecord[customerId] !== undefined) return customerUnreadCountCachedRecord[customerId];

        const url = `/api/notifications/unread_count/${customerId}`;

        const result = await Api.get<{ count: number }>(null, url, undefined, undefined, true);

        if (!result?.data) return customerUnreadCountCachedRecord[customerId];

        if (result?.data?.count && isNaN(result?.data.count)) return customerUnreadCountCachedRecord[customerId];

        customerUnreadCountCachedRecord[customerId] = result.data.count;

        return customerUnreadCountCachedRecord[customerId];
    } catch (error) {
        return customerUnreadCountCachedRecord[customerId];
    } finally {
        // Clear the cache after 15 seconds
        setTimeout(() => {
            customerUnreadCountCachedRecord = {};
        }, 1000 * 15);
    }
};
