import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { SqueegeeEvent } from '../Events/SqueegeeEvent';

export class ActionableEvent extends SqueegeeEvent {
    constructor(
        public message: TranslationKey,
        public action: TranslationKey,
        public handlerOrLink: string | (() => void),
        public timeout = 5000
    ) {
        super();
    }
}
