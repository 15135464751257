import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode } from 'aurelia-framework';

export interface ISortOption {
    name: string;
    title: TranslationKey;
    onSelected: () => void;
    sorter?: (a: any, b: any) => number;
}

export class ListMenuBarCustomElement {
    @bindable({ defaultBindingMode: bindingMode.oneWay }) subtitle: string;
    @bindable({ defaultBindingMode: bindingMode.oneWay }) placeholder: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) searchText: string;
    @bindable() searching: (isSearching: boolean) => void;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) sortOptions: Array<ISortOption>;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) currentSortOption: ISortOption;

    @bindable({ defaultBindingMode: bindingMode.oneWay }) options?: () => void;

    public selectOption(option: ISortOption) {
        this.currentSortOption = option;
        this.hide();
    }

    public hide() {
        const menuEl = document.querySelector('#list-menu-bar .value-container .mdl-menu__container');
        if (menuEl) menuEl.classList.remove('is-visible');
    }
}
