import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import type { IMessage, INewMessage } from '../Message';
import './message-form.scss';

enum KeyCode {
    SHIFT = 'Shift',
    ENTER = 'Enter',

    CTRL = 'Ctrl',
}

export class MessageForm {
    @bindable onMessage: ({ msg }: { msg: INewMessage }) => Promise<void>;
    @bindable onCustomise: () => void;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) protected replyTo?: IMessage;

    newMessage: Partial<IMessage> = {};

    input: HTMLTextAreaElement | undefined;

    private ctrlPressed = false;
    protected placeholderText = ApplicationState.localise('message.write-a-message-placeholder');

    attached() {
        if (this.input) {
            this.input.addEventListener('keypress', this.inputEventListener);
            this.input.addEventListener('keydown', event => (this.ctrlPressed = event.ctrlKey));
            this.input.addEventListener('keyup', event => (this.ctrlPressed = event.ctrlKey));
        }
    }

    protected inputEventListener = (event: KeyboardEvent) => {
        if (event.code === KeyCode.ENTER) {
            if (this.ctrlPressed) {
                event.preventDefault();
                this.send();
            } else {
                // WTF On ios pressing the return key will add a new div element instead of a line break
                document.execCommand('insertLineBreak');
                event.preventDefault();
            }
        }
    };

    private messageIsValid(message: Partial<IMessage>): { valid: boolean; message?: IMessage; errors?: Array<TranslationKey> } {
        const errors: Array<TranslationKey> = [];

        if (!(message && message.content && message.content.trim())) {
            errors.push('validation.description-required');
        }

        return errors.length ? { valid: false, errors: errors } : { valid: true, message: message as IMessage };
    }

    protected send() {
        if (!ApplicationState.isVerifiedForMessaging) {
            new NotifyUserMessage('notification.account-email-not-verified-message-sending');
            return;
        }

        const { valid, message, errors } = this.messageIsValid(this.newMessage);
        if (valid && message) {
            const msg: INewMessage = {
                isConversation: true,
                content: message.content,
            };
            this.onMessage({ msg });
            this.newMessage = {};
        } else if (errors) {
            new NotifyUserMessage(errors[0]);
        }
    }

    protected cancelReply() {
        this.replyTo = undefined;
    }

    protected customise() {
        if (this.onCustomise) this.onCustomise();
    }
}
