import type { SqueegeeCreditPricing, TranslationKey } from "@nexdynamic/squeegee-common";
import { ApplicationState } from "../../ApplicationState";
import { Prompt } from "../../Dialogs/Prompt";
import { TextDialog } from "../../Dialogs/TextDialog";
import { LoaderEvent } from "../../Events/LoaderEvent";
import { Api } from "../../Server/Api";

type PurchaseSqueegeeCreditsOptions = {
    amount?: number;
}

export const purchaseSqueegeeCredits = async (options?: PurchaseSqueegeeCreditsOptions): Promise<{ purchaseComplete: boolean }> => {
    if (!(await ApplicationState.onlineRequiredCheck('credits.online-check'))) return { purchaseComplete: false };

    let quantity = options?.amount || 0;

    if (!options?.amount) {
        const creditPurchaseDialog = new TextDialog(
            'purchase.credits',
            'credits.number-of-credits-to-purchase',
            '1000',
            'number.credits',
            value =>
                isNaN(Number(value)) || !Number.isInteger(Number(value)) || Number(value) < 100 || Number(value) >= 50000
                    ? 'enter.number-between-100-50000-for-credits'
                    : true,
            false,
            'text',
            'general.ok'
        );

        quantity = Number(await creditPurchaseDialog.show());
        if (creditPurchaseDialog.cancelled) return { purchaseComplete: false };
    }

    if (!quantity) return { purchaseComplete: false };

    const smsCreditPricingResponse = await Api.get<SqueegeeCreditPricing>(null, `/api/customer/squeegee-credit-pricing?quantity=${quantity}`);
    const squeegeeCreditPricing = smsCreditPricingResponse?.data;

    if (!squeegeeCreditPricing) {
        await new Prompt('prompts.error-title', 'notifications.squeegee-pricing-unavailable', { cancelLabel: '' }).show();
        return { purchaseComplete: false };
    }
    const rate = squeegeeCreditPricing.amount * (1 + squeegeeCreditPricing.taxPercentage);

    if (
        await new Prompt(
            'general.confirm',
            'credits.purchasing-credits-prompt-description',
            {
                okLabel: 'general.confirm', cancelLabel: 'general.cancel', localisationParams: {
                    cost: `${(quantity * rate).toFixed(2)}` + (ApplicationState.account.language.endsWith('GB') ? ' inc. VAT' : ' + TAX'),
                    creditCount: quantity.toString()
                }
            }
        ).show()
    ) {
        new LoaderEvent(true);
        try {
            const result = await Api.post<{ success: boolean; error: string; credits?: number }>(
                null,
                '/api/customer/purchase-sms-credits',
                { amount: quantity }
            );

            new LoaderEvent(false);
            if (!result || !result.data.success) {
                const message =
                    result && result.data.error && typeof result.data.error === 'string'
                        ? `${result.data.error}` as TranslationKey
                        : 'credits.error-check-card'


                await new Prompt('general.failed', message, { cancelLabel: '' }).show();
            } else {

                await new Prompt('general.success', 'credits.purchase-complete', {
                    cancelLabel: '', localisationParams: {
                        amount: quantity.toString(),
                        totalCredits: result.data.credits?.toString() || ''
                    }
                }).show();

                return { purchaseComplete: true };
            }

        } finally {
            new LoaderEvent(false);
        }
    }
    return { purchaseComplete: false };
}
