import type { Notification, TranslationKey } from '@nexdynamic/squeegee-common';
import { bindable, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { MessageUtils } from '../../Messages';

export class NotificationCustomElement {
    @bindable notification: Notification;

    @computedFrom('notification.status')
    protected get title() {
        if (!this.notification) return;
        const { localise } = ApplicationState;
        return `${localise(('notifications.type-' + this.notification.type) as TranslationKey)} ${localise('general.to')} ${this.notification.address
            }`;
    }

    @computedFrom('notification')
    protected get tags() {
        return MessageUtils.getDeliveryTagsFromNotification(this.notification, 'MMM D YYYY HH:mm');
    }
}
