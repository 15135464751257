import { bindable, bindingMode, computedFrom } from 'aurelia-framework';
import ExternalTemplateDialog from '../../Dialogs/ExternalContent/ExternalTemplateDialog';
import { LoaderEvent } from '../../Events/LoaderEvent';
import type { IMessage } from '../../Messages/Message';
import type { EmailSnippet, TemplateSnippet } from '../../Messages/Snippet';
import { Api } from '../../Server/Api';
import './message-bubble-snippet.scss';

export class MessageBubbleSnippet {
    @bindable({ defaultBindingMode: bindingMode.oneTime }) protected message: IMessage<TemplateSnippet<unknown>>;

    protected async view() {
        if (this.message && this.message.snippet && (this.message.type === 'Email' || this.message.type === 'EmailReply')) {
            new LoaderEvent(true);
            const response = await Api.get<string>(null, `/api/email-engine/${this.message.externalId}/content`);
            new LoaderEvent(false);

            const messageContent = response?.status === 200 ? response?.data : null;

            const snippet = this.message.snippet as EmailSnippet;

            if (messageContent) {
                const cleanContent = messageContent.replace(/ href=".*?"/g, '');
                snippet.model.content = cleanContent;
            }

            const dialog = new ExternalTemplateDialog(
                snippet.templateName,
                { model: snippet.model, dangerouslySetHTML: true },
                this.message.content
            );
            await dialog.show();
        }
    }

    @computedFrom('message.snippet.type')
    get cssClasses() {
        if (this.message && this.message.snippet && this.message.snippet.type) {
            return `message-bubble-snippet--${this.message.snippet.type}`;
        }
        return;
    }
}
