import type { Notification, TranslationKey } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { ApplicationState } from '../../ApplicationState';
import { Data } from '../../Data/Data';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { NotificationService } from '../../Notifications/NotificationService';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import type { IMessage } from '../Message';
import { Message } from '../Message';
import './message-bubble.scss';

export class MessageBubble {
    @bindable({ defaultBindingMode: bindingMode.oneTime }) protected outgoing: boolean;
    @bindable() protected key: string;
    @bindable() protected message: IMessage;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) protected replyTo?: IMessage;

    @computedFrom('message.status')
    protected get status(): string {
        if (!this.message) return '';
        return `message-bubble--${this.message.status}`;
    }

    @computedFrom('message.status')
    protected get statusTitle() {
        return ApplicationState.localise(`messages.status-${this.message.status}` as TranslationKey);
    }

    protected async cancel() {
        try {
            new LoaderEvent(true);
            const notification = await Data.get<Notification>(this.message.id);
            if (!notification || !notification.sendAtSecondsTimestamp || notification.sendAtSecondsTimestamp < Date.now() / 1000) return;

            const result = await NotificationService.cancelScheduledNotifications([notification]);
            if (!result) {
                new NotifyUserMessage('failed.to-cancel');
                return;
            }
        } finally {
            new LoaderEvent(false);
        }
    }

    protected async retry() {
        const notification = Data.get<Notification>(this.message.id);
        if (!notification) return;
        notification.status = 'pending';
        notification.createdDate = new Date().toISOString();
        this.message = Message.fromNotification(notification);
        await Data.put(notification);
    }
}
