// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapsable-menu {
  display: flex !important;
}
.collapsable-menu__title {
  flex: 1;
}
.collapsable-menu__toggle-icon {
  margin-right: 0 !important;
  transform: rotate(0deg);
  transition: transform 180ms ease;
}

.collapsable-content-container {
  max-height: 0;
  overflow-y: hidden;
  transition: max-height 150ms ease-in-out;
}

.expanded .collapsable-content-container {
  max-height: 1000px;
}

.expanded .collapsable-menu__toggle-icon {
  transform: rotate(180deg);
}`, "",{"version":3,"sources":["webpack://./src/Menus/Components/collapsable-menu.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;AACJ;AAAI;EACI,OAAA;AAER;AAAI;EACI,0BAAA;EACA,uBAAA;EACA,gCAAA;AAER;;AAEA;EACI,aAAA;EACA,kBAAA;EACA,wCAAA;AACJ;;AAGA;EACI,kBAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ","sourcesContent":[".collapsable-menu {\n    display: flex !important;\n    &__title {\n        flex: 1;\n    }\n    &__toggle-icon {\n        margin-right: 0 !important;\n        transform: rotate(0deg);\n        transition: transform 180ms ease; // For smooth rotation\n    }\n}    \n\n.collapsable-content-container {\n    max-height: 0;\n    overflow-y: hidden;\n    transition: max-height 150ms ease-in-out; \n\n}\n\n.expanded .collapsable-content-container {\n    max-height: 1000px; \n}\n\n.expanded .collapsable-menu__toggle-icon {\n    transform: rotate(180deg); // Rotate the icon by 180 degrees\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
