import { ApplicationState } from '../ApplicationState';
import { ActionableEvent } from './ActionableEvent';
import { BackgroundTaskUserMessage } from './BackgroundTaskUserMessage';
import { NotifyUserMessage } from './NotifyUserMessage';

export class Notifications {
    private _snackbarContainer: HTMLElement | null;
    private get snackbarContainer(): HTMLElement | null {
        if (!this._snackbarContainer) this._snackbarContainer = document.getElementById('notification-bar');
        return this._snackbarContainer;
    }

    private _snackbarButton: HTMLButtonElement | null;
    private get snackbarButton(): HTMLButtonElement | null {
        if (!this._snackbarButton) this._snackbarButton = <HTMLButtonElement>document.getElementById('notification-bar-button');
        return this._snackbarButton;
    }

    private _snackbarLink: HTMLAnchorElement | null;
    private get snackbarLink(): HTMLAnchorElement | null {
        if (!this._snackbarLink) this._snackbarLink = <HTMLAnchorElement>document.getElementById('notification-bar-link');
        return this._snackbarLink;
    }

    attached() {
        NotifyUserMessage.subscribe((message: NotifyUserMessage) => {
            if (!this.snackbarContainer) return alert(message.text);
            setTimeout(() => {
                (<any>this.snackbarContainer).MaterialSnackbar.hideSnackbar();
                this.snackbarButton && (this.snackbarButton.style.display = 'inherit');
                this.snackbarLink && (this.snackbarLink.style.display = 'none');

                const options = {
                    message: message.text,
                    icon: 'cloud',
                    timeout: message.timeout,
                    actionText: ApplicationState.localise('general.dismiss-message'),
                    actionHandler: this.dismiss,
                };

                (<any>this.snackbarContainer).MaterialSnackbar.showSnackbar(options);
            }, 250);
        });

        BackgroundTaskUserMessage.subscribe((message: BackgroundTaskUserMessage) => {
            if (!this.snackbarContainer) return alert(message.text);
            setTimeout(() => {
                (<any>this.snackbarContainer).MaterialSnackbar.hideSnackbar();
                this.snackbarButton && (this.snackbarButton.style.display = 'inherit');
                this.snackbarLink && (this.snackbarLink.style.display = 'none');

                const options = {
                    message: message.text,
                    icon: 'sync',
                    timeout: -1,
                    actionText: ApplicationState.localise('general.dismiss-message'),
                    actionHandler: this.dismiss,
                };
                (<any>this.snackbarContainer).MaterialSnackbar.showSnackbar(options);
                setTimeout(async () => {
                    await message.promise;
                    this.dismiss();
                }, message.timeout);
            }, 250);
        });

        ActionableEvent.subscribe((message: ActionableEvent) => {
            if (!this.snackbarContainer) return alert(message.message);
            requestAnimationFrame(() => {
                (<any>this.snackbarContainer).MaterialSnackbar.hideSnackbar();
                requestAnimationFrame(() => {
                    if (typeof message.handlerOrLink === 'string') {
                        this.snackbarButton && (this.snackbarButton.style.display = 'none');
                        this.snackbarLink && (this.snackbarLink.style.display = 'inherit');
                        this.snackbarLink && (this.snackbarLink.text = ApplicationState.localise(message.action));
                        this.snackbarLink && (this.snackbarLink.href = message.handlerOrLink);
                        (<any>this.snackbarContainer).MaterialSnackbar.showSnackbar({
                            message: message.message,
                            timeout: message.timeout,
                        });
                    } else {
                        this.snackbarButton && (this.snackbarButton.style.display = 'inherit');
                        this.snackbarLink && (this.snackbarLink.style.display = 'none');
                        (<any>this.snackbarContainer).MaterialSnackbar.showSnackbar({
                            message: message.message,
                            timeout: message.timeout,
                            actionText: message.action,
                            actionHandler: () => {
                                (<() => void>message.handlerOrLink)();
                                this.dismiss();
                            },
                        });
                    }
                });
            });
        });
    }

    protected dismiss = () => {
        const snackbarContainer: HTMLElement | null = this.snackbarContainer;
        if (snackbarContainer) snackbarContainer.classList.remove('mdl-snackbar--active');
    };
}
