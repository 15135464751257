import { type Notification } from "@nexdynamic/squeegee-common";
import { Api } from "../../Server/Api";

type FetchCustomerConversationParams = {
    take: number;
    skip: number;
    customerId?: string;
};

type FetchCustomerConversationResult =
    | {
        success: false;
        error: string;
    }
    | {
        success: true;
        notifications: Array<Notification>;
    };

export const fetchCustomerConversation = async (params: FetchCustomerConversationParams) => {
    try {
        // take the params and add them to the url as query params
        let url = `/api/notifications/conversation?take=${params.take}&skip=${params.skip}`;
        if (params.customerId) url += `&customerId=${params.customerId}`;

        const result = await Api.get<FetchCustomerConversationResult>(null, url);

        if (result?.data.success === true) {
            return result.data.notifications;
        } else {
            throw new Error(result?.data.error);
        }
    } catch (error) {
        throw new Error(error);
    }
};
