import type { TranslationKey } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';

export class SearchMenuBar {
    public searchMenuInput: HTMLInputElement;
    protected placeholderTranslated: string;
    @bindable({ defaultBindingMode: bindingMode.oneWay }) placeholder: TranslationKey;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) searchTerm: string;
    @bindable({ defaultBindingMode: bindingMode.oneWay }) filter: () => void;
    @bindable({ defaultBindingMode: bindingMode.oneWay }) onEnterKey?: () => void;
    @bindable() autoFocus: boolean;

    public placeholderChanged() {
        this.placeholderTranslated = ApplicationState.localise(this.placeholder);
    }

    searchTermChanged() {
        if (this.filter) this.filter();
    }

    public async attached() {
        this.searchMenuInput.selectionStart = 0;
        this.searchMenuInput.selectionEnd = this.searchMenuInput.value.length;
        this.searchMenuInput.addEventListener('keypress', this._keyHandler);
        if (this.autoFocus) {
            setTimeout(() => {
                requestAnimationFrame(() => {
                    this.searchMenuInput.focus();
                });
            }, 250);
        }
    }

    detached() {
        this.searchMenuInput.removeEventListener('keypress', this._keyHandler);
    }

    private _keyHandler = (keyEvent: KeyboardEvent) => {
        if (keyEvent.keyCode === 13 && this.onEnterKey) {
            keyEvent.preventDefault();
            this.onEnterKey();
        }
    };
}
