// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-slot-more-menu .mdc-menu {
  left: initial !important;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/Menus/Components/action-slot-more-menu.scss"],"names":[],"mappings":"AAEI;EACI,wBAAA;EACA,QAAA;AADR","sourcesContent":[".action-slot-more-menu {\n    //Hack can't seem to make menu respect main layout size so on desktop menu gets hidden under a dialog\n    .mdc-menu {\n        left: initial !important;\n        right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
