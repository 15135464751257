import { ApplicationState } from '../ApplicationState';
import { ActionBarEvent } from '../Events/ActionBarEvent';
import type { Subscription } from '../Events/SqueegeeEventAggregator';
import type { IMenuBarAction } from './IMenuBarAction';
export class ActionBar {
    protected actions: Array<IMenuBarAction>;
    protected enabled = false;
    protected closeHandler: any;
    protected count: string | undefined;
    private _actionBarSubscription: Subscription;
    public bar: HTMLElement | undefined;

    protected localise = ApplicationState.localise;

    protected attached() {

        this._actionBarSubscription = ActionBarEvent.subscribe((event: ActionBarEvent) => {
            if (event.count) this.count = event.count;

            this.enabled = event.enable;
            this.closeHandler = event.closeHandler;
            this.actions = (event.actions || []).filter(x => !x.roles?.length || ApplicationState.isInAnyRole(x.roles));
        });

        this.handleSizeChanged();
    }

    handleSizeChanged() {
        const container = document.querySelector('#main-view');

        if (container) {
            const resizer = new ResizeObserver((entries) => {
                const container = entries[0];
                this.bar?.style.setProperty('width', `${container.contentRect.width}px`);
                this.bar?.style.setProperty('left', `${container.contentRect.left}px`);
            })

            resizer.observe(container);
        }
    }

    protected detached() {
        this._actionBarSubscription && this._actionBarSubscription.dispose();
    }

    protected delegateClose = () => {
        if (this.closeHandler) this.closeHandler();
    };
}
