import type { TranslationKey } from "@nexdynamic/squeegee-common";
import { bindable } from "aurelia-framework";
import './collapsable-menu.scss';

export class CollapsableMenuCustomElement {

    @bindable public title: TranslationKey;
    @bindable public icon: string;

    private collapsed = true;

    toggleCollapse() {
        this.collapsed = !this.collapsed;
    }

}