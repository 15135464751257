import type { Notification } from '@nexdynamic/squeegee-common';
import { Api } from '../../Server/Api';

type FetchNotificationParams = {
    id: string;
};

type FetchNotificationResult =
    | {
          success: false;
          error: string;
      }
    | {
          success: true;
          notification: Notification;
      };

export const fetchNotification = async (params: FetchNotificationParams) => {
    try {
        // take the params and add them to the url as query params
        const url = `/api/notifications/get/${params.id}`;

        const result = await Api.get<FetchNotificationResult>(null, url);

        if (result?.data.success === true) {
            return result.data.notification;
        } else {
            throw new Error(result?.data.error);
        }
    } catch (error) {
        throw new Error(error);
    }
};
