import type { Notification } from "@nexdynamic/squeegee-common";
import { Api } from "../../Server/Api";

type FetchNotificationsParams = {
    take: number;
    skip: number;
    customerId?: string;
    unreadOnly?: boolean;
    outbound?: boolean;
    inbound?: boolean;
    scheduled?: boolean;
    relatedItemId?: string;
};

type FetchNotificationsResult =
    | {
        success: false;
        error: string;
    }
    | {
        success: true;
        notifications: Array<Notification>;
    };

export const fetchNotifications = async (params: FetchNotificationsParams) => {
    try {
        // take the params and add them to the url as query params
        let url = `/api/notifications?take=${params.take}&skip=${params.skip}`;
        if (params.customerId) url += `&customerId=${params.customerId}`;
        if (params.unreadOnly) url += `&unreadOnly=true`;
        if (params.outbound) url += `&outbound=true`;
        if (params.inbound) url += `&inbound=true`;
        if (params.scheduled) url += `&scheduled=true`;
        if (params.relatedItemId) url += `&relatedItemId=${params.relatedItemId}`;

        const result = await Api.get<FetchNotificationsResult>(null, url);

        if (result?.data.success === true) {
            return result.data.notifications;
        } else {
            throw new Error(result?.data.error);
        }
    } catch (error) {
        throw new Error(error);
    }

};
